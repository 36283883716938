import NextLink from "next/link";
import { usePathname } from "next/navigation";

import NavListItemContent from "components/organisms/SidebarNav/NavListItemContent";

import { NavBarItem } from "services/navBarListItems";

export interface Props {
  item: NavBarItem;
  open: boolean;
  onClick?: () => void;
}

export default function NavListItem({ item, open, onClick }: Props) {
  if (!item || !item.enabled) {
    return null;
  }

  const asPath = usePathname();

  // NOTE: because /viewings/availability is its own top level nav item, we need to do
  // this check so Viewings and Viewing Availability items aren't both highlighted
  const isViewingAvailability =
    asPath?.startsWith("/viewings/availability") && item.href === "/viewings";

  // NOTE: because `/applications/*` handles new applications and the `Applications`
  // NavBarItem href is `/dashboard`, handle selected for this URL manually
  const isNewApplication =
    asPath === "/applications/add" && item.href === "/dashboard";

  const selected = Boolean(
    (item.href && asPath?.startsWith(item.href) && !isViewingAvailability) ||
      isNewApplication ||
      item.selectedSegments?.some((segment) => asPath?.includes(segment))
  );

  const listItemContent = (
    <NavListItemContent
      text={item.text}
      open={open}
      icon={item.icon}
      preview={item.preview}
      selected={selected}
      onClick={onClick}
    />
  );

  if (item.href) {
    return (
      <NextLink
        href={item.href}
        passHref
        target={item.newTab ? "_blank" : undefined}
      >
        {listItemContent}
      </NextLink>
    );
  }

  return listItemContent;
}
