import {
  AccountBalance,
  AssignmentOutlined,
  Extension,
  FamilyRestroomOutlined,
  FormatListBulletedOutlined,
  HomeWorkRounded,
  Insights,
  LocalLibrary,
  Replay,
} from "@mui/icons-material";

import I18n from "services/I18n";
import {
  communityCardsEnabled,
  insightsNavItemEnabled,
  newLeadsOnActiveLetsEnabled,
  paymentsNavItemEnabled,
} from "services/features";

export interface NavBarItem {
  key?: string;
  href?: string;
  text: string;
  icon?: JSX.Element;
  enabled: boolean;
  newTab: boolean;
  preview: boolean;
  items?: NavBarItem[];
  selectedSegments?: string[];
}

export const findSubMenuItems = [
  {
    href: "/lets",
    text: I18n.t("components.navbar.lets"),
    enabled: newLeadsOnActiveLetsEnabled,
    newTab: false,
    preview: false,
    selectedSegments: ["listings"],
  },
  {
    href: "/listings",
    text: I18n.t("components.navbar.listings"),
    enabled: false,
    newTab: false,
    preview: false,
  },
  {
    href: "/leads",
    text: I18n.t("components.navbar.leads"),
    enabled: true,
    newTab: false,
    preview: false,
  },
  {
    href: "/viewings",
    text: I18n.t("components.navbar.viewings"),
    enabled: true,
    newTab: false,
    preview: false,
  },
  {
    href: "/viewings/availability",
    text: I18n.t("components.navbar.viewingAvailability"),
    enabled: true,
    newTab: false,
    preview: false,
  },
];

export const navBarListItems = (roles: string[] | null) => {
  const isStaff = (roles && roles.includes("staff")) || false;

  return [
    {
      key: "find",
      text: I18n.t("components.navbar.find"),
      icon: <FamilyRestroomOutlined />,
      enabled: true,
      newTab: false,
      preview: false,
      items: findSubMenuItems,
    },
    {
      href: "/applications",
      text: I18n.t("components.navbar.applications"),
      icon: <AssignmentOutlined />,
      enabled: true,
      newTab: false,
      preview: false,
      items: [],
    },
    {
      href: "/stay-summaries",
      text: I18n.t("components.navbar.stays"),
      icon: <FormatListBulletedOutlined />,
      enabled: true,
      newTab: false,
      preview: false,
      items: [],
    },
    {
      href: "/property-summaries",
      text: I18n.t("components.navbar.properties"),
      icon: <HomeWorkRounded />,
      enabled: true,
      newTab: false,
      preview: false,
      items: [],
    },
    {
      href: "/renewals",
      text: I18n.t("components.navbar.renewals"),
      icon: <Replay />,
      enabled: true,
      newTab: false,
      preview: false,
      items: [],
    },
    {
      href: "/community-cards",
      text: I18n.t("components.navbar.community"),
      icon: <LocalLibrary />,
      enabled: communityCardsEnabled,
      newTab: false,
      preview: false,
      items: [],
    },
    {
      href: "/payments/payout-report",
      text: I18n.t("components.navbar.payments"),
      icon: <AccountBalance />,
      enabled: paymentsNavItemEnabled,
      newTab: false,
      preview: false,
      items: [],
    },
    {
      href: "/insights",
      text: I18n.t("components.navbar.insights"),
      icon: <Insights />,
      enabled: insightsNavItemEnabled,
      newTab: false,
      preview: true,
      items: [],
    },
    {
      href: "/integrations",
      text: "Integrations",
      icon: <Extension />,
      enabled: isStaff,
      newTab: false,
      preview: false,
      items: [],
    },
  ];
};
