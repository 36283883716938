import { Box, BoxProps } from "@mui/material";
import { Spacing } from "@residently/components/tokens";
import { useContext } from "react";

import ReturnContext from "context/ReturnContext";

import Link from "components/atoms/Link";

import I18n from "services/I18n";

export default function ReturnLink(props: BoxProps) {
  const { returnTo } = useContext(ReturnContext);

  if (!returnTo) {
    return null;
  }

  const pathStack = new URL(
    returnTo.returnTo,
    process.env.NEXT_PUBLIC_NEIGHBOURHOOD_HOST
  ).pathname.split("/");

  let linkText = "Return to previous page";

  while (pathStack.length > 0) {
    const segment = pathStack.pop();

    if (
      I18n.t(`returnTo.resource.${segment}`, { defaultValue: linkText }) !==
      linkText
    ) {
      const resource = I18n.t(`returnTo.resource.${segment}`);
      linkText = I18n.t("returnTo.prefix", { resource });
      break;
    }
  }

  return (
    <Box mt={Spacing.medium} {...props}>
      <Link text={linkText} href={returnTo.returnTo} />
    </Box>
  );
}
