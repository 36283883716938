import { ClickAwayListener, List } from "@mui/material";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { ReactNode } from "react";

import NavListItem from "components/organisms/SidebarNav/NavListItem";

import { NavBarItem } from "services/navBarListItems";

export interface Props {
  mobileOpen: boolean;
  subMenuOpen?: number;
  subMenuItems?: NavBarItem[];
  onClose?:
    | ((event: object, reason: "backdropClick" | "escapeKeyDown") => void)
    | undefined;
  onSubMenuClick: () => void;
  children?: ReactNode;
}

const drawerWidth = 100;

const StyledDrawer = styled(MuiDrawer)(({ theme }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflowX: "hidden",
  "& .MuiDrawer-paper": {
    background: theme.palette.blue.blue800,
    overflowX: "hidden",
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  background: theme.palette.blue.blue800,
  paddingTop: 0,
  paddingBottom: 0,
}));

export default function Drawer({
  mobileOpen,
  subMenuOpen,
  onClose,
  onSubMenuClick,
  subMenuItems,
  children,
}: Props) {
  let paddingTop = subMenuOpen ? subMenuOpen * 72 : 0;
  paddingTop += mobileOpen ? 56 : 72;

  return (
    <>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
      >
        <StyledDrawer
          variant="temporary"
          open={mobileOpen}
          onClose={onClose}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {children}
        </StyledDrawer>

        <StyledDrawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {children}
        </StyledDrawer>
      </Box>
      {typeof subMenuOpen != "undefined" && subMenuOpen > -1 && (
        <ClickAwayListener onClickAway={onSubMenuClick}>
          <Box
            sx={{
              paddingTop: `${paddingTop}px`,
              position: "fixed",
              left: drawerWidth,
              zIndex: 1300,
            }}
          >
            <StyledList>
              {subMenuItems?.map((item) => (
                <NavListItem
                  key={item.href}
                  item={item}
                  open
                  onClick={onSubMenuClick}
                />
              ))}
            </StyledList>
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
}
